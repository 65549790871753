@mixin shadow-box {
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}

@mixin shadow-box-clickable {
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.15);
}

@mixin shadow-box-hover {
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.16);
}

@mixin shadow-box-active {}

@mixin shadow-spread {
  box-shadow: 0px 6px 30px rgba(0, 0, 0, 0.3);
}

@mixin box-clickable($box) {
  @include shadow-box-clickable();

  &:hover {}
}

@mixin boldFixedWidth($color: currentColor) {
  text-shadow: 0 0.015em $color, 0 -0.015em $color, 0.01em 0 $color, -0.01em 0 $color;
}
