[
  {
    "key": "currentGroup",
    "description": "Currently selected group",
    "entityType": "Group",
    "initialization": "ParameterValue",
    "expression": "myGroups.FirstOrDefault()",
    "allowedValues": "comparisonGroups",
    "readonly": false,
    "expiration": "1.00:00:00"
  },
  {
    "key": "currentGroupCategory",
    "description": "Category for selected group",
    "entityType": "Text",
    "initialization": "Expression",
    "expression": "DynamicData.GetGroup({currentGroup}).Category.Key",
    "readonly": true,
    "expiration": "1.00:00:00"
  },
  {
    "key": "myGroups",
    "description": "All directly connected groups for the current user",
    "entityType": "Group",
    "initialization": "Expression",
    "expression": "DynamicData.GetDirectGroupsForUser()",
    "readonly": true,
    "expiration": "1.00:00:00"
  },
  {
    "key": "comparisonGroups",
    "description": "All available comparison groups for the current user",
    "entityType": "Group",
    "initialization": "Expression",
    "expression": "DynamicData.GetGroupsWithChildren()",
    "readonly": true,
    "expiration": "1.00:00:00"
  },
  {
    "key": "currentSummationGroupChildren",
    "description": "All childgroups of the current summation group with actionplans allowed",
    "entityType": "Group",
    "initialization": "Expression",
    "expression": "DynamicData.GetChildrenGroupsWithAllowedActionPlans(new int[]{{currentGroup}}, {actionPlanMinimumUserCount})",
    "readonly": true,
    "expiration": "1.00:00:00"
  },
  {
    "key": "currentGroupIsLeafNode",
    "description": "Checks whether current group is leafnode or not",
    "entityType": "Number",
    "initialization": "Expression",
    "expression": "DynamicData.CurrentGroupIsLeafNode({currentGroup})",
    "readonly": true,
    "expiration": "1.00:00:00"
  },
  {
    "key": "currentCompany",
    "description": "Currently selected company",
    "entityType": "Company",
    "initialization": "BuiltIn",
    "readonly": true,
    "expiration": "1.00:00:00"
  },
  {
    "key": "currentSnapshot",
    "description": "Latest visible snapshot for the company",
    "entityType": "Snapshot",
    "initialization": "Expression",
    "expression": "DynamicQuery.Value.GetCurrentSnapshot()",
    "validation": "None",
    "expiration": "1.00:00:00"
  },
  {
    "key": "currentSnapshotForGroup",
    "description": "Latest visible snapshot for current group",
    "entityType": "Snapshot",
    "initialization": "Expression",
    "expression": "DynamicQuery.Value.GetCurrentSnapshotForGroup({currentGroup})",
    "validation": "None",
    "expiration": "1.00:00:00"
  },
  {
    "key": "snapshotCounts",
    "description": "Number of snapshots to display results for. Can normally be changed by the user",
    "entityType": "Number",
    "initialization": "Explicit",
    "expression": "3",
    "allowedValues": "1,2,3,4,5",
    "readonly": false,
    "expiration": "1.00:00:00"
  },
  {
    "key": "allCurrentSnapshots",
    "description": "This is just a placeholder parameter now that indicates that the datasource should get the current available snapshots",
    "entityType": "Snapshot",
    "initialization": "Explicit",
    "expression": "$CURRENT",
    "readonly": true,
    "expiration": "1.00:00:00"
  },
  {
    "key": "latestXSnapshots",
    "description": "Latest snapshots for the company",
    "entityType": "Snapshot",
    "initialization": "Expression",
    "expression": "DynamicQuery.Value.LatestGroupSnapshots({currentGroup}, {singleSelectedSnapshot}, -1, true)",
    "readonly": true,
    "expiration": "1.00:00:00"
  },
  {
    "key": "selectedSnapshots",
    "description": "Selected snapshots from the list of available ones, defaults to the first",
    "entityType": "Snapshot",
    "initialization": "ParameterValue",
    "expression": "latestXSnapshots.FirstOrDefault()",
    "readonly": false,
    "expiration": "1.00:00:00"
  },
  {
    "key": "singleSelectedSnapshot",
    "description": "Single selected snapshot from the list of available ones, defaults to the first",
    "entityType": "Snapshot",
    "initialization": "Expression",
    "expression": "DynamicQuery.Value.GetCurrentSnapshotForGroup({currentGroup})",
    "readonly": false,
    "expiration": "1.00:00:00",
    "defaultValue": "0"
  },
  {
    "key": "selectedActionplanSnapshots",
    "description": "Selected snapshots for actionplan view, from the list of available ones",
    "entityType": "Snapshot",
    "initialization": "Expression",
    "expression": "DynamicQuery.Value.GetCurrentSnapshotForGroup({currentGroup})",
    "readonly": false,
    "expiration": "1.00:00:00"
  },
  {
    "key": "currentParentGroups",
    "description": "Parent groups to the currently selected group",
    "entityType": "Group",
    "initialization": "Expression",
    "expression": "DynamicData.GetParentGroupsByHierarchy({currentGroup})",
    "readonly": true,
    "expiration": "1.00:00:00"
  },
  {
    "key": "benchmark",
    "description": "Defines a single benchmark result. The method GetBenchmarkResults has 2 parameters. The first is the externalId of the benchmarkGroup, the second is optional and set the datePeriod for the snapshot. If omitted the latest one will be used",
    "entityType": "GroupResult",
    "initialization": "Expression",
    "expression": "DynamicQuery.Value.GetBenchmarkResults(new string[]{\"Global\"}, null)",
    "readonly": true,
    "expiration": "1.00:00:00"
  },
  {
    "key": "allBenchmarks",
    "description": "Defines multiple benchmark results. See description for benchmark parameter for info about datePeriods for benchmarks.",
    "entityType": "GroupResult",
    "initialization": "Expression",
    "expression": "DynamicQuery.Value.GetBenchmarkResults(new string[]{\"Global\", \"HP25\"}, null)",
    "readonly": true,
    "expiration": "1.00:00:00"
  },
  {
    "key": "selectedBenchmarks",
    "description": "Currently selected benchmarks",
    "entityType": "GroupResult",
    "initialization": "ParameterValue",
    "expression": "allBenchmarks.FirstOrDefault()",
    "readonly": false,
    "expiration": "1.00:00:00"
  },
  {
    "key": "selectedComparisonGroups",
    "description": "Selected comparison groups",
    "entityType": "Group",
    "initialization": "Expression",
    "expression": "{currentGroup}",
    "allowedValues": "comparisonGroups",
    "readonly": false,
    "expiration": "1.00:00:00"
  },
  {
    "key": "selectedActionplanGroups",
    "description": "Selected groups for the actionplan explore view",
    "entityType": "Group",
    "initialization": "ParameterValue",
    "expression": "currentSummationGroupChildren",
    "allowedValues": "comparisonGroups",
    "readonly": false,
    "expiration": "1.00:00:00"
  },
  {
    "key": "actionPlanMinimumUserCount",
    "description": "Minimum number of users to count a group for action plans",
    "entityType": "Number",
    "initialization": "Explicit",
    "expression": "3",
    "expiration": "1.00:00:00"
  },
  {
    "key": "resultListIndexFilter",
    "description": "Selected indexes in indexlist",
    "entityType": "Index",
    "initialization": "Explicit",
    "expression": "",
    "expiration": "1.00:00:00"
  },
  {
    "key": "comparisonIndexResultValue",
    "description": "Number of comparison index results to display",
    "entityType": "Number",
    "initialization": "Explicit",
    "expression": "4",
    "readonly": false,
    "expiration": "1.00:00:00"
  },
  {
    "key": "actionplanOverviewTimeFrame",
    "description": "Selected time frame",
    "entityType": "Date",
    "initialization": "None",
    "expression": null,
    "readonly": false,
    "expiration": "1.00:00:00"
  },
  {
    "key": "actionplanExploreTimeFrame",
    "description": "Selected time frame",
    "entityType": "Date",
    "initialization": "None",
    "expression": null,
    "readonly": false,
    "expiration": "1.00:00:00"
  },
  {
    "key": "allowedActionPlanCategories",
    "description": "List of categories that allows action plans",
    "entityType": "Text",
    "initialization": "Expression",
    "expression": "DynamicData.GetAllowedActionPlanCategories()",
    "readonly": true,
    "expiration": "1.00:00:00"
  },
  {
    "key": "currentSensitivityLevel",
    "description": "Sensitivity level for current group",
    "entityType": "Text",
    "initialization": "Expression",
    "expression": "DynamicData.GetCurrentSensitivityLevel({currentGroup})",
    "readonly": true,
    "expiration": "1.00:00:00"
  },
  {
    "key": "currentRole",
    "description": "Role to the current group",
    "entityType": "Text",
    "initialization": "Expression",
    "expression": "DynamicData.GetRoleForCurrentGroup({currentGroup})",
    "readonly": true,
    "expiration": "1.00:00:00"
  }
]
