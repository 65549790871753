    $uikit-button-border-width: 2px;
    $color-white: #fff;


    // Base styles for all buttons
    @mixin button-base {

      // Sizing
      margin: 0;
      padding: 0;
      box-sizing: border-box;
      position: relative;
      font-size: 1rem;
      font-family: mark;
      font-weight: bold;
      -ms-grid-row-align: center;

      // Reset browser <button> styles.
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      cursor: pointer;
      outline: none;
      border: none;
      -webkit-tap-highlight-color: transparent;
      touch-action: manipulation;

      // The `outline: none` from above works on all browsers, however Firefox also
      // adds a special `focus-inner` which we have to disable explicitly. See:
      // https://developer.mozilla.org/en-US/docs/Web/HTML/Element/button#Firefox
      &::-moz-focus-inner {
        border: 0;
      }

      &:hover * {
        text-decoration: none;
        color: inherit;
      }

      /* Make anchors render like buttons.
  */
      display: inline-flex;
      justify-content: center;
      align-items: center;
      white-space: nowrap;
      text-decoration: none;
      vertical-align: baseline;
      text-align: center;

      /* Explicitly set the default overflow to `visible`. It is already set
     on most browsers except on IE11 where it defaults to `hidden`.
     */
      overflow: visible;

      /* Default button color palette */
      background-color:$color-white;
      border-radius: 500px;
      transition: 0.2s;
      transition: position 0s;
      height: auto;
      line-height: 1.25rem;
      color: inherit;
    }

    .uikit-button-wrapper {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      padding: 10px 16px;
      white-space: normal;
      width: 100%;

      &:has(>span:not(:empty)) {
        column-gap: 8px;
      }
    }
