export interface SurveyList {
  endDate: Date;
  groupNames: string[];
  participantCount: number;
  productName?: string;
  productId: number;
  responseCount: number;
  responseRate?: number;
  startDate: Date;
  surveyId: number;
  surveyName: string;
  isCustomName?: boolean;
  createdBy?: string;
  activatedBy?: string;
  activatedOn?: Date;
  selectable?: boolean;
}

export interface PinCode {
  groupName: string;
  pinCode: string;
}

export enum SurveyStatus {
  Ongoing = 'Ongoing',
  Upcoming = 'Upcoming',
  Finished = 'Finished'
}

export interface SurveyListModel {
  id: number;
  name: string;
  communicationType: string;
}

