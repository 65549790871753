// Colors will have to be updated here, AND in ui-kit/color-scheme.ts
$color-bg: #f2f2f2;

$color-black: #000;
$color-white: #fff;
$color-grey1: $color-black;
$color-grey2: #4f4f4f;
$color-grey3: #828282;
$color-grey4: #bdbdbd;
$color-grey5: #e0e0e0;
$color-grey6: #f2f2f2;
$color-grey7: #f8f8f8;

$color-gray1: $color-grey1;
$color-gray2: $color-grey2;
$color-gray3: $color-grey3;
$color-gray4: $color-grey4;
$color-gray5: $color-grey5;
$color-gray6: $color-grey6;
$color-gray7: $color-grey7;

$color-petrol-dark: #005e58;
$color-petrol: #007a73;
$color-petrol-light: #4D9F9A;
$color-petrol-lightest: #C1dddb;
$color-petrol-super-duper-light: #e5f1f0;

$color-highlight: #D9F1E1;

$color-dark-blue: #003660;
$color-blue: #004275;
$color-blue-light: #22a6c8;
$color-blue-cx: #326790;
$color-blue-lightest-cx: #C4D9E9;

$color-pink: #f04e98;
$color-easy-blue: #22a6c8;

$color-result-red: #f42737;
$color-result-orange: #ff6a13;
$color-result-yellow: #ffd800;
$color-result-mint-green: #a0dab3;
$color-result-green: #00a139;
$color-result-blue: #123abe;

$color-good-default: #21a90c;
$color-bad-dark: #ac0009;
$color-bad-default: #ca0f27;
$color-bad-light: #de233b;
$color-bad-lightest: #e54f62;

$EI_Yellow: #ffd800;
$EI_Green: #a0dab3;
$EI_DarkGreen: #00a139;
$EI_Red: #f42737;
$EI_Blue: #123abe;

$tonality-positive: #A0DAB3;
$tonality-neutral: #C4C4C4;
$tonality-negative: #FF6A13;


$color-graph-orange-lightest: #FFBC58;

$survey-gradient-default: linear-gradient(330.73deg, #105650 0%, rgba(41, 127, 118, 0.9) 97.83%);

$gradient1: linear-gradient(143.95deg, #a0dab3 0%, #007a73 97.44%);
$gradient2: linear-gradient(120.17deg, #A0DAB3 0%, #00756E 97.44%),
  #004275;

$shadow-clickable: 0px 3px 6px rgba(0, 0, 0, 0.15);
$shadow-not-clickable: 0px 2px 4px rgba(0, 0, 0, 0.1);
$shadow-hover-state: 0px 10px 20px rgba(0, 0, 0, 0.16);
$shadow-box-spread: 0px 6px 30px rgba(0, 0, 0, 0.3);
$shadow-drop-down: 0px 10px 20px rgba(0, 0, 0, 0.16);

:root {
  --insight-color-result-red: #{$color-result-red};
  --insight-color-result-orange: #{$color-result-orange};
  --insight-color-result-yellow: #{$color-result-yellow};
  --insight-color-result-mint-green: #{$color-result-mint-green};
  --insight-color-result-green: #{$color-result-green};
  --insight-color-result-blue: #{$color-result-blue};

  --insight-color-result-5grade-lowest: #{$color-result-red};
  --insight-color-result-5grade-low: #{$color-result-orange};
  --insight-color-result-5grade-medium: #{$color-result-yellow};
  --insight-color-result-5grade-high: #{$color-result-mint-green};
  --insight-color-result-5grade-highest: #{$color-result-green};

  --insight-color-result-3grade-low: #{$color-result-red};
  --insight-color-result-3grade-medium: #{$color-result-yellow};
  --insight-color-result-3grade-high: #{$color-result-green};
}
