@import 'button-base';
@import 'button-theme';
@import 'ui-kit/styles/effects';

@mixin uikit-primary-base {
  background-color: $uikit-button-primary-bg;
  color: $uikit-button-primary-text;
  border: $uikit-button-border-width solid transparent;

  &:not([disabled]):hover {
    background-color: $uikit-button-primary-hover-bg;
  }

  &:not([disabled]):active {
    background-color: $uikit-button-primary-active-bg;
  }

  .icon-button:hover,
  .menu-button:hover,
  .icon-button:active,
  .menu-button:active {
    background-color: inherit;
  }

  &[disabled] {
    background-color: $color-grey5;
  }
}

@mixin uikit-secondary-base {
  background-color: $uikit-button-secondary-bg;
  color: $uikit-button-secondary-text;
  border: $uikit-button-border-width solid $uikit-button-secondary-border;

  &:not([disabled]):hover,
  &.hover {
    color: $uikit-button-secondary-hover-text;
    border-color: $uikit-button-secondary-hover-border;
    background-color: $uikit-button-secondary-hover-bg;
  }

  &:not([disabled]):active {
    background-color: $uikit-button-secondary-active-bg;
    color: $uikit-button-secondary-active-text;
    border: $uikit-button-border-width solid $uikit-button-secondary-active-border;
  }

  &:not([disabled]).selected,
  &:not([disabled])[selected='true'] {
    background-color: $color-pink;
    color: $uikit-button-primary-text;
    border: $uikit-button-border-width solid $uikit-button-secondary-active-border;
    border-color: $uikit-button-secondary-inverted-border;
  }
}

.uikit-button {
  @include button-base;

  &.min-width {
    min-width: 120px;
  }

  &.max-width {
    width: 100%;
    max-width: 343px;
  }

  .button-icon {
    font-size: 1.25rem;
  }

  &.icon .button-icon {
    padding: 0;
    display: inline-flex;
  }

  &.icon.icon-right .button-icon {
    padding-left: 8px;
  }

  &.uikit-primary {
    @include uikit-primary-base;

    &.inverted {
      background-color: $uikit-button-primary-inverted-bg;
      color: $uikit-button-primary-inverted-text;

      &:not([disabled]):hover,
      &.hover {
        background-color: $uikit-button-primary-inverted-hover-bg;
      }

      &:not([disabled]):active {
        background-color: $uikit-button-primary-inverted-active-bg;
      }

      &[disabled] {
        background-color: $uikit-button-primary-inverted-disabled;
      }
    }

    &.delete {
      background-color: $uikit-button-primary-delete-bg;
      color: $uikit-button-primary-delete-text;

      &:not([disabled]):hover,
      &.hover {
        background-color: $uikit-button-primary-delete-hover-bg;
      }

      &:not([disabled]):active {
        background-color: $uikit-button-primary-delete-active-bg;
      }
    }

    &.context {
      &.min-width {
        min-width: 130px;
      }

      height: 36px;
      font-size: 0.875rem;
      border: $uikit-button-border-width solid transparent;

      background-color: $uikit-button-context-fill-bg;
      color: $uikit-button-context-fill-text;

      &:not([disabled]):hover,
      &.hover {
        background-color: $uikit-button-context-fill-hover-bg;
      }

      &:not([disabled]):active {
        background-color: $uikit-button-context-fill-active-bg;
      }

      &[disabled] {
        background-color: $uikit-button-context-fill-disabled;
      }
    }

    &.context-no-fill {
      height: auto;
      min-width: auto;
      font-size: 0.875rem;
      line-height: 1;
      border: 0;
      border-radius: 0;

      background-color: transparent;
      color: $color-petrol;

      .uikit-button-wrapper {
        padding: 0;
        gap: 8px;
      }

      &:not([disabled]):hover,
      &.hover {
        color: $color-petrol-light;
        background-color: inherit;
      }

      &:not([disabled]):active {
        color: $color-petrol-dark;
        background-color: inherit;
      }

      &.delete {
        color: $color-bad-default;

        &:not([disabled]):hover,
        &.hover {
          color: $color-bad-light;
        }

        &:not([disabled]):active {
          color: $color-bad-dark;
        }
      }

      &[disabled] {
        color: $color-grey4;
      }
    }

    &.icon-right .uikit-button-wrapper {
      flex-flow: row-reverse;
    }

    &.context.icon,
    &.context-no-fill {
      font-weight: normal;
      line-height: 1.25rem;

      .button-icon {
        margin: 0;
        font-size: 1rem;
      }

      &.big {
        font-size: 16px;
      }

      .button-icon {
        line-height: 12px;
      }
    }

    &.context {
      .uikit-button-wrapper {
        padding: 0 12px;
      }
    }

    &.context.icon,
    &.context.icon-right,
    &.context-no-fill.icon-right {

      .button-icon,
      .label {
        margin: 0;
        padding: 0;
      }
    }

    &.context.icon {

      .button-icon,
      .label {
        margin: 0;
      }
    }

    &.context-no-fill.icon {
      .button-icon {
        margin: 0;
        padding: 0;
      }
    }

    &.context-no-fill.icon-right {
      .button-icon {
        margin: 0;
      }
    }
  }

  &.uikit-secondary {
    @include uikit-secondary-base;

    &.inverted {
      background-color: $uikit-button-secondary-inverted-bg;
      color: $uikit-button-secondary-inverted-text;
      border: $uikit-button-border-width solid $uikit-button-secondary-inverted-border;

      &:not([disabled]):hover,
      &.hover {
        color: $uikit-button-secondary-inverted-hover-text;
        border-color: $uikit-button-secondary-inverted-hover-border;
        background-color: $uikit-button-secondary-inverted-hover-bg;
      }

      &:not([disabled]):active {
        background-color: $uikit-button-secondary-inverted-active-bg;
        color: $uikit-button-secondary-inverted-active-text;
        border: $uikit-button-border-width solid $uikit-button-secondary-inverted-active-border;
      }
    }

    &.delete {
      background-color: $uikit-button-secondary-delete-bg;
      color: $uikit-button-secondary-delete-text;
      border: $uikit-button-border-width solid $uikit-button-secondary-delete-border;

      &:not([disabled]):hover,
      &.hover {
        color: $uikit-button-secondary-delete-hover-text;
        border-color: $uikit-button-secondary-delete-hover-border;
        background-color: $uikit-button-secondary-delete-hover-bg;
      }

      &:not([disabled]):active {
        background-color: $uikit-button-secondary-delete-active-bg;
        color: $uikit-button-secondary-delete-active-text;
        border: $uikit-button-border-width solid $uikit-button-secondary-delete-active-border;
      }
    }
  }

  &.uikit-error {
    background-color: $uikit-button-primary-delete-bg;
    color: $uikit-button-primary-delete-text;

    &:not([disabled]):hover,
    &.hover {
      background-color: $uikit-button-primary-delete-hover-bg;
      color: $uikit-button-primary-delete-text;
    }

    &:not([disabled]):active {
      background-color: $uikit-button-primary-delete-active-bg;
      color: $uikit-button-primary-delete-text;
    }

    &[disabled] {
      background-color: $uikit-button-primary-delete-disabled;
      color: $uikit-button-primary-delete-text;
    }
  }

  &.uikit-ghost {
    background-color: transparent;
    color: inherit;
    border-color: transparent;

    &:not([disabled]):hover,
    &.hover {
      color: inherit;
      border-color: $uikit-button-ghost-hover-border;
      background-color: $uikit-button-ghost-hover-bg;
    }

    &:not([disabled]):active {
      background-color: $uikit-button-ghost-active-bg;
    }
  }

  &.inverted.survey:not([disabled])[selected='false'] {
    background-color: transparent;
    color: #fff;
    border-color: #fff;

    /* No hover on touch devices */
    @media (hover: hover) and (pointer: fine) {
      &:hover {
        background-color: #fff;
        color: $color-petrol;
      }
    }
  }

  &.inverted.survey:not([disabled])[selected='true'] {
    background-color: $color-pink;
    color: #fff;
    border-color: #fff;
  }

  &:not([disabled]):hover,
  &:not([disabled]):focus {
    text-decoration: none;
  }

  &[disabled] {
    cursor: default;
    color: $color-black;
    opacity: 0.4;
  }

  &[selected='true'] {
    background-color: #000;
    color: white;
  }

  &.cdk-keyboard-focused {
    border: $uikit-button-border-width solid $uikit-button-primary-border !important;

    .uikit-button-focus-overlay {
      opacity: 1;
      outline: none !important;
    }
  }

  &.big {
    height: 60px;
    min-width: 328px;
  }

  &.small .uikit-button-wrapper {
    height: 36px;
    font-size: 0.875rem;
  }

  &.small.min-width .uikit-button-wrapper {
    min-width: 120px;
    padding: 0 24px;
  }

  &.icon-button {
    border-radius: 500px;
    width: 40px;
    height: 40px;
    font-size: 1em;
    color: inherit;
    background-color: $color-petrol;
    aspect-ratio: 1;

    &.small {
      border-radius: 400px;
      height: 24px;
      width: 24px;
      font-size: 12px;
      padding: 0px 0px 0px 2px;
    }

    &:not([disabled]):hover {
      @include shadow-box-hover;
    }

    &:not([disabled]):active {
      @include shadow-box-clickable;
    }

    .uikit-button-wrapper {
      padding: 0;
    }

    span.label {
      display: inherit;
    }
  }

  &.menu-button {
    height: 32px;
    width: 32px;
    font-size: 0.875rem;
    line-height: 1;
    border: $uikit-button-border-width solid transparent;
    border-radius: 0;
    background-color: transparent;
    color: $color-black;
    border-width: 1px;
    border-color: transparent;
    border-radius: 4px;

    .uikit-button-wrapper {
      padding: 0 0.5rem;
    }

    &:disabled {
      background-color: transparent;
    }

    &:hover:not(:disabled) {
      color: $color-black;
      background: $color-grey6;
    }

    &:active:not(:disabled) {
      color: $color-black;
      background: $color-grey5;
    }

    &:focus:not(:active):not(:disabled) {
      border-color: $color-black;
    }

    &.icon .button-icon {
      padding-right: 0;
    }

    .uikit-icon {
      font-size: 1rem;
    }
  }
}

a.uikit-button,
a.uikit-button.uikit-primary {
  text-decoration: underline;
  font-size: 1rem;
  background-color: inherit;
  color: $color-petrol;
  height: auto;
}

.animation-wrapper {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  max-width: 100px;
  position: absolute;

  .animation-container {
    width: 100%;
    margin: 0.5rem;
  }
}

.unclickable {
  pointer-events: none;
}
