@import 'ui-kit/styles/palette';

//Primary
$uikit-button-primary-bg: $color-petrol;
$uikit-button-primary-text: $color-white;

$uikit-button-primary-hover-bg: $color-petrol-light;
$uikit-button-primary-active-bg: $color-petrol-dark;
$uikit-button-primary-border: $color-black;

$uikit-button-primary-disabled: $color-grey5;

// Secondary
$uikit-button-secondary-bg: transparent;
$uikit-button-secondary-text: $color-black;
$uikit-button-secondary-border: $color-black;

$uikit-button-secondary-hover-bg: transparent;
$uikit-button-secondary-hover-text: $color-petrol;
$uikit-button-secondary-hover-border: $color-petrol;

$uikit-button-secondary-active-bg: transparent;
$uikit-button-secondary-active-text: $color-petrol-dark;
$uikit-button-secondary-active-border: $color-petrol-dark;

$uikit-button-secondary-disabled-text: $color-black;
$uikit-button-secondary-disabled-border: $color-black;

//Ghost

$uikit-button-ghost-hover-bg: $color-grey6;
$uikit-button-ghost-hover-border: $color-grey6;

$uikit-button-ghost-active-bg: $color-grey5;


/* INVERTED
*/

//Primary
$uikit-button-primary-inverted-bg: $color-white;
$uikit-button-primary-inverted-text: $color-black;

$uikit-button-primary-inverted-hover-bg: $color-grey5;
$uikit-button-primary-inverted-active-bg: $color-grey4;
$uikit-button-primary-inverted-border: $color-black;

$uikit-button-primary-inverted-disabled: $color-white;

// Secondary
$uikit-button-secondary-inverted-bg: transparent;
$uikit-button-secondary-inverted-text: $color-white;
$uikit-button-secondary-inverted-border: $color-white;

$uikit-button-secondary-inverted-hover-bg: $color-white;
$uikit-button-secondary-inverted-hover-text: $color-petrol;
$uikit-button-secondary-inverted-hover-border: $color-white;

$uikit-button-secondary-inverted-active-bg: $color-petrol-light;
$uikit-button-secondary-inverted-active-text: $color-petrol;
$uikit-button-secondary-inverted-active-border: $color-grey4;

$uikit-button-secondary-inverted-disabled-text: $color-white;
$uikit-button-secondary-inverted-disabled-border: $color-white;

/*  DELETE  */

// Primary
$uikit-button-primary-delete-bg: $color-bad-default;
$uikit-button-primary-delete-text: $color-white;

$uikit-button-primary-delete-hover-bg: $color-bad-light;
$uikit-button-primary-delete-active-bg: $color-bad-dark;
$uikit-button-primary-delete-border: $color-black;

$uikit-button-primary-delete-disabled: $color-bad-default;

// Secondary
$uikit-button-secondary-delete-bg: transparent;
$uikit-button-secondary-delete-text: $color-black;
$uikit-button-secondary-delete-border: $color-black;

$uikit-button-secondary-delete-hover-bg: transparent;
$uikit-button-secondary-delete-hover-text: $color-bad-default;
$uikit-button-secondary-delete-hover-border: $color-bad-default;

$uikit-button-secondary-delete-active-bg: transparent;
$uikit-button-secondary-delete-active-text: $color-bad-dark;
$uikit-button-secondary-delete-active-border: $color-bad-dark;

$uikit-button-secondary-delete-disabled-text: $color-black;
$uikit-button-secondary-delete-disabled-border: $color-black;




// context
$uikit-button-context-fill-bg: $color-grey6;
$uikit-button-context-fill-text: $color-black;

$uikit-button-context-fill-hover-bg: $color-grey5;
$uikit-button-context-fill-active-bg: $color-grey4;
$uikit-button-context-fill-border: $color-black;

$uikit-button-context-fill-disabled: $color-grey6;


